@import "./colors.scss";
@import "./sizes.scss";

#search-status-ui {
  margin-left: $left-margin;
  &.show {
    max-height: 80vh;
    transition: max-height 0.5s
  }
  &.no-show {
    overflow: hidden;
    max-height: 0vh;
    transition: max-height 0.5s
  }
  .error-no-show {
      opacity: 0;
      visibility: hidden;
  }
  .helptext-small {
    font-size: 16px;
    font-weight: 100;
    line-height: initial;
  }
  .error {
    color: $msg-warning;
    padding-top: 8px;
    padding-left: 20px;
  }
  ol, ul {
    list-style: none;
  }
  .results-for {
    padding: 0;
    min-width: 230px;
    line-height: 40px;
    font-size: 36px;
    color: $grey-7;
  }
  .param-labels {
    padding: 0;
    font-size: 20px;
    color: $grey-5;
  }
  .params {
    padding: 0;
    font-size: 20px;
    font-weight: bold;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .affiliation-name {
    font-size: 14px;
    font-weight: 700;
  }
  .affiliation-id {
    font-size: 10px;
    font-weight: normal;
    color: $grey-5;
  }
  .more-affiliations {
    padding-left: 0;
    color: #000;
    height: 28px;
    font-family: NexusSansPro, sans-serif;
    border: 0px;
    font-size: 14px;
  }
  .affiliation-up {
    position: absolute;
    left: 120px;
    display: inline-block;
    height: 24px;
    width: 18px;
    mask: url(./svg/gizmo-navigate-up.svg) no-repeat 50% 50%;
    background-color: $primary-orange;
    background-size: 18px 24px;
    transition: all .3s;
    &.rotated {
      transform: rotate(180deg);
      transition: all .3s
    }
  }
  .all-affiliations-window {
    position: absolute;
    background-color: #fff;
    z-index: 99;
    margin-top: 12px;
    margin-right: 1em;
    border: solid 1px $grey-4;
    border-top: solid 2px $primary-orange;
    padding: 10px 20px 0px 20px;
    .carot {
      position: absolute;
      top: 12px;
      left: 8px;
      height: 20px;
      width: 20px;
      -webkit-transform-origin: 100% 0;
      -ms-transform-origin: 100% 0;
      transform-origin: 100% 0;
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
      border-left: solid 2px $primary-orange;
      #triangle {
        width: 0;
        height: 0;
        border-top: 20px solid white;
        border-right: 20px solid transparent;
      }
    }
    header {
      padding: 0 0 4px 0;
      font-weight: 900;
      font-size: 14px;
      font-style: italic;
      .cancel-x {
        position: absolute;
        cursor: pointer;
        right: 8px;
        display: inline-block;
        height: 16px;
        width: 16px;
        mask: url(./svg/gizmo-delete.svg) no-repeat 50% 50%;
        background-color: $primary-petrol-blue;
        background-size: 12px 16px;
      }
    }
    .affiliations-header {
      padding: 0 0 4px 0;
      border-bottom: solid 2px $grey-2;
    }
    ul.all-affiliations-list {
      padding: 4px 0 12px 0;
      max-height: 280px;
      margin-bottom: 12px;
      border-bottom: solid 2px $grey-3;
      overflow-y: scroll;
      li {
        padding: 8px 0;
        line-height: 1.1;
      }
    }
    
  }
  .content-types {
    padding-left: 0;
    color: $primary-petrol-blue;
    height: 28px;
    font-family: NexusSansPro, sans-serif;
    border: 0px;
    font-size: 14px;
    background-color: #fff;
  }
  .content-types:hover {
    text-decoration: underline;
  }
  .content-type-name {
    font-size: 14px;
    font-weight: 700;
  }
  .content-types-up {
    position: absolute;
    left: 120px;
    display: inline-block;
    height: 24px;
    width: 18px;
    mask: url(./svg/gizmo-navigate-up.svg) no-repeat 50% 50%;
    background-color: $primary-orange;
    background-size: 18px 24px;
    transition: all .3s;
    &.rotated {
      transform: rotate(180deg);
      transition: all .3s
    }
  }
  .all-content-types {
    position: absolute;
    background-color: #fff;
    z-index: 99;
    margin-top: -12px;
    margin-right: 1em;
    border: solid 16px rgba(220, 220, 220, 0.85);
    border-left: solid 2px $primary-orange;
    padding: 10px 20px 0px 20px;
    left: 25px;
    min-width: 250px;
    .carot {
      position: absolute;
      top: -14px;
      left: -36px;
      height: 20px;
      width: 20px;
      -webkit-transform-origin: 100% 0;
      -ms-transform-origin: 100% 0;
      transform-origin: 100% 0;
      -webkit-transform: rotate(224deg);
      -ms-transform: rotate(224deg);
      transform: rotate(224deg);
      border-top: solid 2px $primary-orange;
      #triangle {
        width: 0;
        height: 0;
        border-top: 20px solid rgba(220, 220, 220, 0.85);
        border-right: 20px solid transparent;
      }
    }
    header {
      padding: 0 0 4px 0;
      font-weight: 900;
      font-size: 14px;
      font-style: italic;
      .cancel-x {
        position: absolute;
        cursor: pointer;
        right: 8px;
        display: inline-block;
        height: 16px;
        width: 16px;
        mask: url(./svg/gizmo-delete.svg) no-repeat 50% 50%;
        background-color: $primary-petrol-blue;
        background-size: 12px 16px;
      }
    }
    .content-types-header {
      padding: 0 0 4px 0;
      border-bottom: solid 2px $grey-2;
    }
    ul.all-content-types-list {
      padding: 4px 0 12px 0;
      max-height: 280px;
      margin-bottom: 12px;
      overflow-y: scroll;
      li {
        padding: 8px 0;
        line-height: 1.1;
      }
    }
  }
}
