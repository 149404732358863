@font-face {
  font-family: 'NexusSansPro';
  src:  url('./nexussanswebpro-regular.woff2') format('woff2'),
        url('./nexussanswebpro-regular.ttf') format('ttf');
}

body {
  font-family: NexusSansPro;
  margin: 0px;
  padding: 0px;
}

.hide {
  display: none;
}
