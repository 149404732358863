@import '../colors.scss';

.input-text-area {
  position: relative;

  textarea {
    margin: 20px 10px 0px 0px;
    width: 100%;
    min-height: 28px;
    max-height: 100px;
    font-family: NexusSansPro, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    resize: none;
    display: block;
    color: $grey-8;
    border: none;
    border-bottom: 2px solid $grey-3;
    &:hover {
      border-bottom: 2px solid $grey-7;
    }
    &:focus{
      outline-color: transparent;
      outline-style: none;
      border-bottom: 2px solid $grey-7;
    }
    &::-webkit-search-cancel-button{
      -webkit-appearance: none;
      position:relative;
      height: 24px;
      width: 19px;
      mask: url(./../svg/gizmo-delete.svg) no-repeat 50% 50%;
      background-color: $primary-petrol-blue;
      background-size: 19px 24px;
    }
  }

  label {
    position: absolute;
    font-size: 14px;
    color: $primary-petrol-blue;
    transition: top .3s;
    top: -24px;
    &.placeholder-text {
      cursor: pointer;
      top: 0px;
      transition: top .8s;
    }
    .example-text {
      pointer-events: none;
      padding-left: 4px;
      color: $grey-4;
      z-index: -99;
    }
  }
}