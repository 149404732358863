@import './colors.scss';
@import './sizes.scss';

#search-input {
  .helptext-small {
    font-size: 10px;
    font-weight: 100;
  }
  .error {
  color: #c83727;
  padding-top: 8px;
  }
  .search-input-body {
    padding: 0px $left-margin;
    transition: all 0.5s;
    &.collapsed {
      max-height: 0px;
      overflow: hidden;
      transition: all 0.5s;
    }
    .author-input {
      opacity: 1;
      transition: opacity 0.3s, visibility 0.3s;
    }
    .full-name {
      margin-top: -48px;
    }
    .no-show {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;
    }
    .section-title {
      font-size: 16px;
      padding: 8px 0;
      &.display-inline {
        display: inline-block;
      }
    }
    .section-row {
      padding-top: 32px;
    }
    .checkbox > input {
        -webkit-appearance: none;
        border: solid 1px #000;
        height: 12px;
        width: 12px;
      }
      .checkbox > input:checked {
        background-color: #e9711c;
        box-shadow: 0 0 0 0.10em $white inset;
      }
    .checkbox-selected {
    -webkit-appearance: none;
    background-color: #e9711c;
    border: 2px solid $black-2;
    box-shadow: 0 0 0 0.15em $white inset;
    box-sizing: border-box;
    display: inline-block;
    font: inherit;
    height: calc(1em + 4px);
    margin: 0;
    padding: 0;
    position: relative;
    vertical-align: middle;
    width: calc(1em + 4px);
    transition: background-color var(--es-animation-hover), border-color var(--es-animation-hover);
    }
    .filter-label {
      font-size: 14px;
    }
    .source-label {
            padding: 8px;
            font-size: 14px;
            color: $primary-petrol-blue;

    }
    input[type='radio'] {
      -webkit-appearance:none;
      width:20px;
      height:20px;
      border:1px solid black;
      border-radius:50%;
      outline:none;
    }
    
    input[type='radio']:hover {
      box-shadow:0 0 5px 0px orange inset;
    }
    
    input[type='radio']:before {
      content:'';
      display:block;
      width:60%;
      height:60%;
      margin: 20% auto;    
      border-radius:50%;    
    }
    input[type='radio']:checked:before {
      background: #e9711c;
    }
    .pubmed-details, .pubmed-date-tooltip  {
      position: absolute;
      z-index: 99;
      background-color: rgba(220, 220, 220, 1);
      border-bottom: solid 1px #e9711c;
      padding: 5px;
      width: 315px;
      header {
        padding: 0 0 4px 0;
        font-weight: 900;
        font-size: 14px;
        font-style: italic;
        .cancel-x {
          position: absolute;
          cursor: pointer;
          right: 8px;
          display: inline-block;
          height: 16px;
          width: 16px;
          mask: url(./svg/gizmo-delete.svg) no-repeat 50% 50%;
          background-color: $primary-petrol-blue;
          background-size: 12px 16px;
        }
      }
      .carot {
        position: absolute;
        left: 19px;
        height: 10px;
        width: 10px;
        bottom: -11px;
        -webkit-transform-origin: 100% 0;
        -ms-transform-origin: 100% 0;
        transform-origin: 100% 0;
        -webkit-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        transform: rotate(225deg);
        border-top: solid 1px $primary-orange;
        #triangle {
          width: 0;
          height: 0;
          border-top: 20px solid rgba(220, 220, 220, 1);
          border-right: 20px solid transparent;
        }
      }
    }
    .orcid-details {
      top: -60px;
      right: 0;
    }
    .pubmed-details {
      top: -84px;
      right: 0;
    }
    .pubmed-date-tooltip {
      margin-top: -180px;
      left: 134px;
    }
  }

  .search-footer {
    margin: 0px $left-margin;
    padding: 16px 0px;
    border-bottom: 2px solid $black;
    .search-buttons {
      text-align: center;
      &.left-align {
        text-align: left;
      }
    }
  }

  .export-status-link {
    display: inline-block;
    float: right;
    button {
      &.primary-link {
        padding: 5px 0px;
      }
    }
  }

  /* buttons from style guide */
  button {
    cursor: pointer;
    height: 28px;
    font-size: 20px;
    font-family: NexusSansPro, sans-serif;
    color: $white;
    background-color: $primary-petrol-blue;
    border: 0px;
    &:active {
      color: $grey-6;
    }
    &.primary-link {
      display: inline-block;
      background-color: $white;
      color: $primary-petrol-blue;
      text-align: left;
      padding: 5px 15px;

      &:hover {
        color: $primary-orange;
      }
      &.collapsed {
        display: none;
      }
    }
    &.primary-search, &.clear-search, &.modify-search, &.cancel-modify {
      width: 178px;
      height: 40px;
      margin-right: 20px;
      padding-left: 12px;
      text-align: left;
      position: relative;
      &:active {
        color: $grey-6;
        .magnify-glass {
          background-color: $grey-6;
        }
      }
      &:disabled {
        background-color: $grey-3;
      }
      &.collapsed {
        display: none;
      }
      .magnify-glass {
        position: absolute;
        right: 12px;
        display: inline-block;
        height: 24px;
        width: 19px;
        mask: url(./svg/gizmo-search.svg) no-repeat 50% 50%;
        background-color: $white;
        background-size: 19px 24px;
      }
      .cancel-x {
        position: absolute;
        right: 12px;
        display: inline-block;
        height: 24px;
        width: 19px;
        mask: url(./svg/gizmo-delete.svg) no-repeat 50% 50%;
        background-color: $primary-petrol-blue;
        background-size: 19px 24px;
      }
      .nav-up {
        position: absolute;
        left: 12px;
        display: inline-block;
        height: 24px;
        width: 18px;
        mask: url(./svg/gizmo-navigate-up.svg) no-repeat 50% 50%;
        background-color: $white;
        background-size: 18px 24px;
        transition: all .3s;
        &.rotated {
          transform: rotate(180deg);
          transition: all .3s
        }
      }
      .nav-right {
        position: absolute;
        right: 12px;
        display: inline-block;
        height: 24px;
        width: 18px;
        mask: url(./svg/gizmo-navigate-up.svg) no-repeat 50% 50%;
        background-color: $primary-petrol-blue;
        background-size: 18px 24px;
        transition: all .3s;
        &.rotate-right {
          transform: rotate(90deg);
          transition: all .3s
        }
      }
      .document-icon {
        margin-top: 4px;
        display: inline-block;
        height: 18px;
        width: 19px;
        mask: url(./svg/export-document.svg) no-repeat 50% 50%;
        background-color: $white;
        background-size: 19px 24px;
        float: right;
      }
      &.clear {
        background-color: #fff;
        border: 2px solid $primary-petrol-blue;
        color: $black;
        &:disabled {
          cursor: not-allowed;
          border: 2px solid $grey-4;
          color: $grey-4;
          .cancel-x {
            background-color: $grey-4;
          }
        }
    }
    }
    &.left-icon {
      padding-left: 40px;
    }
    &.small {
      font-size: 16px;
    }
    &.large {
      font-size: 20px;
    }
    .retweet-icon {
      vertical-align: bottom;
      padding: 0px 24px;
      display: inline-block;
      height: 24px;
      width: 24px;
      mask: url(./svg/gizmo-retweet.svg) no-repeat 50% 50%;
      background-color: $primary-petrol-blue;
      background-size: 24px 24px;
    }
  }
}
