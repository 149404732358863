@import './colors.scss';
@import './sizes.scss';

#search-results-ui {
  margin: 16px $left-margin;

  .row {
    margin: 0; /* overiding bootstrap default */
  }
  .col-sm-10, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1,
  .col-md-10, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1 {
    padding: 0px;
  }

  .export-header {
        border-bottom: 1px solid $grey-7;
        display:inline-block;
        text-align: left;
        margin-right: 40px;
        width: 100%;
  }
  .export-button {
        cursor: pointer;
        height: 40px;
        font-size: 20px;
        font-family: NexusSansPro, sans-serif;
        color: $white;
        background-color: $primary-petrol-blue;
        border: 0px;
        padding: 8px 20px 8px 20px;
        margin-bottom: 14px;
        width: 178px;
        text-align: left;
        position: relative;
        display: block;
  }
  .export-status-link {
    button {
      &.primary-link {
        padding: 5px 0px;
      }
    }
  }
  .document-icon {
        margin-top: 4px;
        display: inline-block;
        height: 18px;
        width: 19px;
        mask: url(./svg/export-document.svg) no-repeat 50% 50%;
        background-color: $white;
        background-size: 19px 24px;
        float: right;
  }


  ol {
    padding-inline-start: 0px;
    list-style: none;
    margin: 0;

    &.results-list {
      padding-bottom: 32px;
      border-bottom: solid 2px $grey-7;
    }

    &.result-header {
      border-top: 1px solid $grey-7;
      border-bottom: 1px solid $grey-7;
      padding: 8px 0;

      .head-type, .head-date {
        color: $black;
      }
    }
    &.results-footer {
      padding: 20px 0;
      .pagination {
        line-height: 34px;
        text-align: center;
        .icon-prev-next, .icon-first-last {
          margin: auto;
          padding: 5px 4px 4px 2px;
          line-height: 22px;
          max-height: 32px;
          max-width: 32px;
        }
        .icon-prev-next {
          border: solid 2px $primary-petrol-blue;
        }
        .icon-first-last {
          border: solid 2px $grey-3;
        }

        .first-page, .prev-page, .next-page, .last-page {
          height: 18px;
          width: 18px;
          background-color: $primary-petrol-blue;
          background-size: 10px 12px;
          &:disabled {
              background-color: $grey-3;
          }
          &:active {
            background-color: $black;
          }
          &:hover {
            text-shadow: 0px 0px 3px $grey-7;
          }
        }
        .first-page {
          mask: url(./svg/gizmo-skip-back.svg) no-repeat 50% 50%;
        }
        .prev-page {
          mask: url(./svg/gizmo-rewind.svg) no-repeat 50% 50%;
        }
        .next-page {
          mask: url(./svg/gizmo-fast-forward.svg) no-repeat 50% 50%;
        }
        .last-page {
          mask: url(./svg/gizmo-skip-forward.svg) no-repeat 50% 50%;
        }
        .previous, .next {
          line-height: 34px;
          height: 32px;
          &:disabled {
            color: $grey-3;
          }
          &:active {
            color: $black;
            text-shadow: 0px 0px 2px $grey-5;
          }
          &:hover {
            text-shadow: 0px 0px 3px $grey-3;
          }
        }
      }
      .to-top {
        background-color: transparent;
        float: right;
      }
    }
  }
  .result-item {
    margin: 0;
    margin-top: 20px;
    &:not(:last-child) {
      margin-bottom: 20px;
      padding-bottom: 12px;
      border-bottom: solid 2px $grey-2;
    }
    .line-number{
        padding-left: 20px;
        text-align: center;
    }
    .pub-title {
      .source-index {
        color: $primary-orange;
        font-size: 20px;
        .oa-label {
          font-size: 14px;
          color: $white;
          background-color: $primary-petrol-blue;
          cursor: pointer;
        }
        .oa-label:hover {
          color: $black;
          background-color: $white;
          border: 1px solid $primary-orange;
        }
        .oa-label-details {
          position: absolute;
          z-index: 99;
          bottom: calc(100%);
          left: calc(100% - 10px);
          background-color: $white;
          border: solid 16px rgba(220, 220, 220, 0.85);
          border-bottom: solid 1px $primary-orange;
          padding: 5px;
          width: 315px;
          color: $black;
          font-size: 14px;
          header {
            padding: 0 0 4px 0;
            font-weight: 900;
            font-size: 14px;
            .cancel-x {
              position: absolute;
              cursor: pointer;
              right: 8px;
              display: inline-block;
              height: 16px;
              width: 16px;
              mask: url(./svg/gizmo-delete.svg) no-repeat 50% 50%;
              background-color: $primary-petrol-blue;
              background-size: 12px 16px;
            }
          }
          .carot {
            position: absolute;
            left: -18px;
            height: 10px;
            width: 10px;
            bottom: -18px;
            -webkit-transform-origin: 100% 0;
            -ms-transform-origin: 100% 0;
            transform-origin: 100% 0;
            -webkit-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: rotate(135deg);
            border-top: solid 1px $primary-orange;
            #triangle {
              width: 0;
              height: 0;
              border-top: 20px solid rgba(220, 220, 220, 1);
              border-right: 20px solid transparent;
            }
          }
        }
      }
      .open-access {
        padding: 2px 12px;
        background-color: $grey-7;
        color: $white;
        font-size: 14px;
        font-weight: 700;
      }
      .title {
        padding-right: 12px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 700;
        color: $text-grey;
        word-wrap: break-word;
        a {
          color: $black;
          border-bottom: 2px solid $white;
          text-decoration: none;
          &:hover {
            border-bottom: 2px solid $primary-orange;
          }
        }
      }
    }
    .authors {
      padding: 4px 12px 0 0;
      font-size: 14px;
      line-height: 1.5;
      color: $grey-7;
      .author-name {
        color: $black;
        font-weight: 700;
      }
    }
    .all-authors-window {
      position: absolute;
      bottom: 10px;
      left: 160px;
      background-color: #fff;
      z-index: 99;
      border-left: solid 2px $primary-orange;
      width: 420px;
      height: 210px;
      color: #2E2E2E;
      .all-authors-content {
        height: 100%;
        overflow: scroll;
        border: 10px solid $grey-2;
        .carot {
          position: absolute;
          top: 210px;
          left: -36px;
          height: 20px;
          width: 20px;
          background: $grey-2;
          -webkit-transform-origin: 100% 0;
          -ms-transform-origin: 100% 0;
          transform-origin: 100% 0;
          -webkit-transform: rotate(-135deg);
          -ms-transform: rotate(-135deg);
          transform: rotate(-135deg);
          border-right: solid 2px $primary-orange;
          #triangle {
            width: 0;
            height: 0;
            border-top: 20px solid white;
            border-right: 20px solid transparent;
          }
        }
        header {
          padding: 0 0 4px 0;
          font-weight: 900;
          .cancel-x {
            position: absolute;
            right: 15px;
            display: inline-block;
            height: 16px;
            width: 16px;
            mask: url(./svg/gizmo-delete.svg) no-repeat 50% 50%;
            background-color: $primary-petrol-blue;
            background-size: 12px 16px;
          }
        }
        .authors-header {
          padding: 0 0 4px 0;
          font-style: italic;
          border-bottom: solid 2px $grey-2;
        }
        ul.all-authors-list {
          padding: 4px 0 12px 0;
          max-height: 280px;
          margin-bottom: 12px;
          li {
            padding: 8px 0;
            line-height: 1.1;
          }
        }
      }
    }
    .more-authors {
      margin-top: 8px;
      padding-left: 8px;
    }
    .author-up {
      position: absolute;
      left: 120px;
      display: inline-block;
      height: 24px;
      width: 18px;
      mask: url(./svg/gizmo-navigate-up.svg) no-repeat 50% 50%;
      background-color: $primary-orange;
      background-size: 18px 24px;
      transition: all .3s;
      &.rotated {
        transform: rotate(180deg);
        transition: all .3s
      }
    }
    .doc-type, .pub-date {
      padding-top: 4px;
      color: $text-grey;
    }
  }
  button {
    cursor: pointer;
    height: 28px;
    font-family: NexusSansPro, sans-serif;
    color: $primary-petrol-blue;
    border: 0px;
  }

  .bubble-wrap {
    width: 100%;
    padding: 50px;
    margin: auto;
  }

  .circle {
    animation:grow 2.1s infinite;
    animation-timing-function: ease-in-out;
    position: absolute;
    left: calc(50% - 16px);
    width: 0px;
    height: 0px;
    border-radius: 100%;
    border: solid 10px $primary-lt-blue;
    opacity: 0.5;
    &.middle {
     animation-delay: .25s;
     left: calc(50% + 0px);
    }
    &.last {
      animation-delay: .5s;
      left: calc(50% + 16px);
    }
  }

  @keyframes grow {
   0% {
      -webkit-transform: scale( 0.5 );
      -moz-transform: scale( 0.5  );
      -o-transform: scale( 0.5 );
      -ms-transform: scale( 0.5 );
      transform: scale( 0.5 );
    }

    50% {
      -webkit-transform: scale( 1.5 );
      -moz-transform: scale( 1.5 );
      -o-transform: scale( 1.5 );
      -ms-transform: scale( 1.5 );
      transform: scale( 1.5 );
    }

    100% {
      -webkit-transform: scale( 0.5 );
      -moz-transform: scale( 0.5 );
      -o-transform: scale( 0.5 );
      -ms-transform: scale( 0.5 );
      transform: scale( 0.5 );
    }
  }
}
