@import './colors.scss';

@keyframes fadeInAnimation{
  0%{ opacity: 0; }
  100%{ opacity: 1; }
}

.modal-dialog {
  max-width: 660px;
}

.modal-header {
  display: block;
  padding: 0;
}

.modal-body {
  padding: 0;
}

#modal-error {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: rgba(50, 50, 50, 0.3);

  animation: fadeInAnimation ease .3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  .dialog-box {
    max-width: 660px;
    min-height: 353px;
    margin: 100px auto;
    background-color: $white;
    border: solid 16px rgba(220, 220, 220, 0.85);

    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    hr {
      margin: 0;
      border: 2px solid $primary-orange;
    }
    .inline {
      display: inline-block;
    }

    .tool-kit {
      margin: 72px 44px;
      width: 98px;
      height: 98px;
      background: url(./svg/elsevier-pictogram-0078-toolkit.svg) no-repeat top left;
      background-size: contain;
    }

    .cancel-x {
      z-index: 10;
      position: absolute;
      top: 5px;
      right: 15px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      background-color: $grey-6; /* defines the background color of the image */
      mask: url(./svg/gizmo-delete.svg) no-repeat center / contain;
      -webkit-mask: url(./svg/gizmo-delete.svg) no-repeat center / contain;
    }

    .dialog-text {
      margin: 72px 12px 0 0;
      vertical-align: top;
      
      .error-title {
        max-width: 412px;
        font-size: 36px;
        color: $grey-7;
      }
      .error-detail {
        max-width: 412px;
      }
    }

    .bottom-row {
      width: 100%;
      height: 40px;
      padding: 0 28px;
      margin-bottom: 28px;
      .confirm {
        float: right;
      }
    }
    /* button needs css refactor to sit on root */
    button {
      cursor: pointer;
      width: 227px;
      height: 40px;
      font-size: 20px;
      line-height: 1.4;
      font-family: NexusSansPro, sans-serif;
      color: $white;
      background-color: $primary-petrol-blue;
      border: 0px;
      &:active {
        color: $grey-6;
      }
    }
  }
}

#modal-export {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);

  animation: fadeInAnimation ease .3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  text-align: left;

  .dialog-box {
    max-width: 95%;
    min-height: 615px;
    margin: 30px auto;
    background-color: $white;
    border: solid 16px rgba(220, 220, 220, 0.85);

    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;

    .bubble-wrap {
    width: 100%;
    padding: 50px;
    margin: auto;
    }
    .circle {
    animation:grow 2.1s infinite;
    animation-timing-function: ease-in-out;
    position: absolute;
    left: calc(45% - 30px);
    top: 120px;
    width: 0px;
    height: 0px;
    border-radius: 100%;
    border: solid 20px $primary-lt-blue;
    opacity: 0.5;
        &.middle {
         animation-delay: .25s;
         left: calc(45% + 0px);
        }
        &.last {
          animation-delay: .5s;
          left: calc(45% + 30px);
        }
    }

    .check-mark {
      margin: 72px 44px;
      width: 98px;
      height: 98px;
      background: url(./svg/0020-why-should-i-use-it.svg) no-repeat top left;
      background-size: contain;
    }

    .cancel-x {
      z-index: 10;
      position: absolute;
      top: 20px;
      right: 10px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      background-color: $grey-6; /* defines the background color of the image */
      mask: url(./svg/gizmo-delete.svg) no-repeat center / contain;
      -webkit-mask: url(./svg/gizmo-delete.svg) no-repeat center / contain;
    }


    .bottom-row {
      width:450px;
      float:right;
      text-align: right;
      padding: 0 25px;
      position: absolute;
      bottom: 0px;
      right: 0px;
      .confirm {
        width: 178px;
        height: 43px;
        background-color: #fff;
        border: 2px solid $primary-petrol-blue;
        color: #000000;
        text-align: left;
        padding-left: 20px;
      }
      .export-button {
        cursor: pointer;
        height: 43px;
        font-size: 20px;
        font-family: NexusSansPro, sans-serif;
        color: $white;
        background-color: $primary-petrol-blue;
        border: 0px;
        padding: 8px 20px 8px 20px;
        margin-bottom: 14px;
        margin-left: 12px;
        margin-right: 0px;
        width: 178px;
        text-align: left;
        position: relative;
        float: right;
        &.complete {
            width: 217px;
        }
        &:disabled {
            background-color: $grey-3;
        }
      }
      .document-icon {
        margin-left: 12px;
        margin-top: 4px;
        display: inline-block;
        height: 18px;
        width: 19px;
        mask: url(./svg/export-document.svg) no-repeat 50% 50%;
        background-color: $white;
        background-size: 19px 24px;
        float: right;
      }
      .no-show {
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s, visibility 0.3s;
      }
    }
    /* button needs css refactor to sit on root */
    button {
      cursor: pointer;
      width: 227px;
      height: 40px;
      font-size: 20px;
      line-height: 1.4;
      font-family: NexusSansPro, sans-serif;
      color: $white;
      background-color: $primary-petrol-blue;
      border: 0px;
      &:active {
        color: $grey-6;
      }
    }

    hr {
      margin: 0;
      border: 2px solid $primary-orange;
    }
    .inline {
      display: inline-block;
    }

    .tool-kit {
      margin: 72px 44px;
      width: 98px;
      height: 98px;
      background: url(./svg/elsevier-pictogram-0078-toolkit.svg) no-repeat top left;
      background-size: contain;
    }

    .publication-columns {
        margin: 16px 12px 0 24px;
        height: 400px;
        .publication-types {
            float: left;
            padding-right: 15px;
            .publication-type-list {
                padding-left: 5px;
                overflow: auto;
            }
            .publication-type-list-title {
                border-bottom: 2px solid $grey-6;
                padding-bottom: 4px;
                margin-bottom: 8px;
            }
        }
        .radiobutton-off {
            width: 24px;
            height: 24px;
            border-radius: 24px;
            border: solid 2px $grey-4;
            margin: 4px;
            text-align: center;
        }
        .radiobutton-selected {
            width: 12px;
            height: 12px;
            border-radius: 24px;
            background-color: #e9711c;
            margin: 4px;
            display: inline-block
        }
        .list-text {
            padding: 4px;
            font-family: NexusSansPro;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            color: $black-2;
        }
        .list-text-selected {
            padding: 4px;
            font-family: NexusSansPro;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            color: #e9711c;
            border-bottom: 2px solid #e9711c;
            padding-bottom: 1px;
            margin-bottom: 1px;
        }
        .column-divider {
            width: 10px;
            float: left;
            padding-left: 5px;
        }
        .publications {
            top: 0;
            float: left;
            padding-left: 10px;
            padding-right: 10px;
            .publication-list {
              height: 300px;
                padding-left: 10px;
                overflow-y: scroll;
                overflow-x: hidden;
            }
            .publication-list-title {
                border-bottom: 2px solid $grey-6;
                padding-bottom: 4px;
                margin-bottom: 8px;
            }
            ::-webkit-scrollbar {
              -webkit-appearance: none;
              width: 6px;
            }
            
            ::-webkit-scrollbar-thumb {
              border-radius: 3px;
              background-color: $grey-4;
            }
        }
        .options {
          float:left;
          .options-title {
            border-bottom: 2px solid #737373;
            padding-bottom: 4px;
            margin-bottom: 8px;
          }
          .checkbox {
            position: absolute;
            left: 1em;
          }
    
          .checkbox > input {
            -webkit-appearance: none;
            border: solid 2px #B9B9B9;
            height: 12px;
            width: 12px;
          }
          .checkbox > input:checked {
            background-color: #e9711c;
          }
          .checkbox > input:disabled {
            opacity: 0.3;
          }
          .checkbox-label {
            font-size: 10pt;
            text-align: left;
            padding-left: 35px;
            padding-bottom: 5px;
            padding-top: 5px;
            a {
              text-decoration: underline;
            }
          }
          
          .checkbox-label-disabled {
            opacity: 0.3;
          }
        }

    }

    .dialog-text {
      margin: 24px 12px 0 24px;
      vertical-align: top;
      .export-title {
        max-width: 412px;
        font-size: 26px;
        color: $grey-7;
      }
      .exporting-title {
        width: 560px;
        font-size: 40px;
        color: $grey-7;
        text-align: center;
      }
      .export-detail {
        max-width: 412px;
      }

      .error-title {
        max-width: 412px;
        font-size: 36px;
        color: $grey-7;
      }
      .error-detail {
        max-width: 400px;
        font-size: 18px;
      }
    }

    .complete-dialog-text {
      margin: 72px 12px 0 0;
      vertical-align: top;
      .complete-title {
        max-width: 412px;
        font-size: 36px;
        color: $grey-7;
      }
      .complete-detail {
        max-width: 412px;
      }
    }


  }
}

#modal-affiliations {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 120vh;
  background-color: rgba(50, 50, 50, 0.3);
  overflow: hidden;

  .dialog-box {
    min-height: 400px;
    margin: 100px auto;
    background-color: $white;
    border: solid 16px rgba(220, 220, 220, 0.85);

    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;
    

    .dialog-table {
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 60px;

      .affiliation-table {
        width: 100%;

        th {
          font-weight: normal;
          font-size: 16px;
        }
    
        td {
          height: 56px;
          padding-bottom: 20px;
          vertical-align: top;
        }
      }
      .radiobutton-off {
            width: 24px;
            height: 24px;
            border: solid 2px $grey-4;
            margin: 4px;
            text-align: center;
        }
        .radiobutton-selected {
            width: 12px;
            height: 12px;
            background-color: #e9711c;
            margin: 4px;
            display: inline-block
        }
    }

    hr {
      margin: 0;
      border: 2px solid $primary-orange;
    }

    .inline {
      display: inline-block;
    }

    .tool-kit {
      margin: 72px 44px;
      width: 98px;
      height: 98px;
      background: url(./svg/elsevier-pictogram-0078-toolkit.svg) no-repeat top left;
      background-size: contain;
    }
  
    .dialog-text {
      .affiliations-title {
        font-size: 20px;
        color: $grey-7;
      }
      .affiliations-detail {
        font-size: 14px;
      }
      .error-title {
        max-width: 412px;
        font-size: 36px;
        color: $grey-7;
      }
      .error-detail {
        max-width: 412px;
      }
      .cancel-x {
        cursor: pointer;
        z-index: 10;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        background-color: $grey-6; /* defines the background color of the image */
        mask: url(./svg/gizmo-delete.svg) no-repeat center / contain;
        -webkit-mask: url(./svg/gizmo-delete.svg) no-repeat center / contain;
      }
    }

    .dialog-footer {
      width: 100%;
      padding: 0px 28px;
      text-align: right;
      position: absolute;
      bottom: 0px;
      right: 0px;
      background: #fff;
  
      .affiliations-button {
        cursor: pointer;
        height: 43px;
        font-size: 20px;
        font-family: NexusSansPro, sans-serif;
        color: $white;
        background-color: $primary-petrol-blue;
        border: 0px;
        padding: 8px 20px 8px 20px;
        margin-bottom: 14px;
        margin-left: 12px;
        margin-right: 0px;
        width: 178px;
        text-align: left;
        position: relative;
        float: right;
        &.complete {
            width: 217px;
        }
      }

      .disabled {
        cursor: not-allowed;
        background-color: $grey-4;
      }

      .back-button {
        cursor: pointer;
        height: 43px;
        font-size: 20px;
        font-family: NexusSansPro, sans-serif;
        color: $white;
        background-color: $primary-petrol-blue;
        border: 0px;
        padding: 8px 20px 8px 20px;
        margin-bottom: 14px;
        margin-left: 12px;
        margin-right: 0px;
        text-align: left;
        position: relative;
        float: right;
      }

      .cancel-button {
        width: 178px;
        height: 43px;
        text-align: left;
        font-size: 20px;
        font-family: NexusSansPro, sans-serif;
        background-color: #fff;
        border: 2px solid $primary-petrol-blue !important;
        color: $black;
        padding-left: 12px;
        
      }
  
      .magnify-glass {
        position: absolute;
        right: 12px;
        display: inline-block;
        height: 24px;
        width: 19px;
        mask: url(./svg/gizmo-search.svg) no-repeat 50% 50%;
        background-color: $white;
        background-size: 19px 24px;
      }
    }
  }
}

#modal-export-status {
  z-index: 1000;
  width: 100%;
  height: 120vh;
  background-color: rgba(50, 50, 50, 0.3);
  overflow: hidden;

  .modal-dialog {
    max-width: 820px;
  }

  .dialog-box {
    min-height: 400px;
    margin: 100px auto;
    background-color: $white;
    border: solid 16px rgba(220, 220, 220, 0.85);

    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;
    
    .dialog-table {
      max-height: 300px;
      overflow-y: auto;
      overflow-x: scroll;

      th {
        font-weight: normal;
        font-size: 16px;
      }
  
      td {
        height: 56px;
        padding-bottom: 20px;
        padding-top: 20px;
        padding-right: 20px;
        vertical-align: top;
      }
    }

    hr {
      margin: 0;
      border: 2px solid $primary-orange;
    }

    .dialog-text {
      .affiliations-title {
        font-size: 20px;
        color: $grey-7;
      }
      .cancel-x {
        cursor: pointer;
        z-index: 10;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        background-color: $grey-6; /* defines the background color of the image */
        mask: url(./svg/gizmo-delete.svg) no-repeat center / contain;
        -webkit-mask: url(./svg/gizmo-delete.svg) no-repeat center / contain;
      }
    }
  }
}

#modal-content-types {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 120vh;
  background-color: rgba(50, 50, 50, 0.3);
  overflow: hidden;

  .modal-dialog {
    max-width: 95%;
    margin: 100px auto;
    background-color: $white;
    border: solid 16px rgba(220, 220, 220, 0.85);
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;
  }

  .dialog-box {
    min-height: 400px;
    background-color: $white;

    hr {
      margin-top: 15px;
      width: 95%;
    }
    
    .dialog-table {
      max-height: 600px;
      overflow-y: auto;
      overflow-x: scroll;

      ul > li {
        font-size: 14px;
        min-width: 150px;
      }

      ul > li > hr {
        width: 110%;
        margin: 0;
        background-color: $primary-petrol-blue;
      }

      .checkbox > input {
        -webkit-appearance: none;
        border: solid 2px #B9B9B9;
        height: 12px;
        width: 12px;
      }
      .checkbox > input:checked {
        background-color: #e9711c;
      }
      .checkbox > input:disabled {
        opacity: 0.3;
      }
      .checkbox-label {
        font-size: 9pt;
        text-align: left;
        padding-left: 25px;
        padding-bottom: 5px;
      }
    }

    .dialog-text {
      .content-types-title {
        font-size: 20px;
        color: $grey-7;
      }
      .checkbox > input {
        -webkit-appearance: none;
        border: solid 2px #B9B9B9;
        height: 14px;
        width: 14px;
      }
      .checkbox > input:checked {
        background-color: #e9711c;
      }
      .cancel-x {
        cursor: pointer;
        z-index: 10;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        background-color: $grey-6; /* defines the background color of the image */
        mask: url(./svg/gizmo-delete.svg) no-repeat center / contain;
        -webkit-mask: url(./svg/gizmo-delete.svg) no-repeat center / contain;
      }
    }

    .dialog-footer {
      width: 100%;
      padding: 0px 28px;
      text-align: right;
      position: absolute;
      bottom: 0px;
      right: 0px;
      background: #fff;
  
      .ok-button {
        cursor: pointer;
        height: 43px;
        font-size: 20px;
        font-family: NexusSansPro, sans-serif;
        color: $white;
        background-color: $primary-petrol-blue;
        border: 0px;
        padding: 8px 20px 8px 20px;
        margin-left: 12px;
        margin-right: 0px;
        width: 100px;
        text-align: left;
        position: relative;
        float: right;
        &.complete {
            width: 217px;
        }
        .nav-right {
          position: absolute;
          right: 12px;
          display: inline-block;
          height: 29px;
          width: 18px;
          mask: url(./svg/gizmo-navigate-up.svg) no-repeat 50% 50%;
          background-color: $white;
          background-size: 18px 24px;
          transition: all .3s;
          &.rotate-right {
            transform: rotate(90deg);
            transition: all .3s
          }
        }
      }

      .disabled {
        cursor: not-allowed;
        background-color: $grey-4;
      }

      .cancel-button {
        width: 178px;
        height: 43px;
        text-align: right;
        font-size: 20px;
        font-family: NexusSansPro, sans-serif;
        background-color: #fff;
        color: $primary-petrol-blue;
        padding-left: 12px;
      }
  
      .magnify-glass {
        position: absolute;
        right: 12px;
        display: inline-block;
        height: 24px;
        width: 19px;
        mask: url(./svg/gizmo-search.svg) no-repeat 50% 50%;
        background-color: $white;
        background-size: 19px 24px;
      }
    }    
  }
}
