@import '../colors.scss';

.input-text-field {
  position: relative;
  ul.input-drop-down {
    z-index: 10;
    position: absolute;
    min-width: 45%;
    overflow-x: scroll;
    max-height: 180px;
    list-style: none;
    cursor: pointer;
    padding: 0px;
    background: $white;
    color: $black;
    border: 2px solid $grey-3;
    border-top: 0;
    li {
      padding: 0px 10px;
      position: relative;
      span {
        border-bottom: 2px solid transparent;
      }
      &:hover {
        list-style-type: none;
        span {
          border-bottom: 2px solid $primary-orange;
        }
      }
    }
  }

  input {
    margin: 20px 10px 0px 0px;
    width: 100%;
    height: 28px;
    font-family: NexusSansPro, sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    display: block;
    color: $grey-8;
    border: none;
    border-bottom: 2px solid $grey-3;
    &:hover {
      border-bottom: 2px solid $grey-7;
    }
    &:focus{
      outline-color: transparent;
      outline-style: none;
      border-bottom: 2px solid $grey-7;
    }
    &::-webkit-search-cancel-button{
      -webkit-appearance: none;
      position:relative;
      height: 24px;
      width: 19px;
      mask: url(./../svg/gizmo-delete.svg) no-repeat 50% 50%;
      background-color: $primary-petrol-blue;
      background-size: 19px 24px;
    }
  }

  label {
    position: absolute;
    font-size: 14px;
    color: $primary-petrol-blue;
    transition: top .3s;
    top: -24px;
    &.placeholder-text {
      cursor: pointer;
      top: 0px;
      transition: top .8s;
    }
    .example-text {
      pointer-events: none;
      padding-left: 4px;
      color: $grey-4;
      z-index: -99;
    }
  }
}
