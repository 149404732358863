@import './colors.scss';
@import './sizes.scss';

.no-wrap {
  white-space: nowrap;
}

/** personal overwrite for full width grids - wiley **/
.container {
  .col-sm-10, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1 {
    padding: 0px 5px 0px 8px;
  }
}

.inline-block {
  display: inline-block;
}

.attention {
  font-weight: bold
}

label {
  width: auto !important;
}

button:focus {
  outline: none;
}

.search-header {
  width: 100%;
  margin: 0px;
  padding: 32px 40px 32px 0px;
}

div[disabled]
{
  pointer-events: none;
  opacity: 0.5;
  .input-text-field label {
    color: #000000;
  }
}
